import type {IAppConfig} from "@shared/interfaces/IWebAppWorkerRecord";
import type {defaultAppLocale} from '@shared/_data/defaultAppLocale';

const defaultConfig = {
	pwaStartUrl: '?pwa=true',
}

interface CAppConfig {
	subId: string;
	pwaStartUrl: string;
	cId: string;
	rId: string;
}

export function getConfig() {
	return {...defaultConfig, ...window.__appConfig} as IAppConfig & CAppConfig;
}

export function hasConfig() {
	return !!window.__appConfig;
}

export function createGetLocale<T extends keyof typeof defaultAppLocale>() {
	return (key: keyof typeof defaultAppLocale[T]) => {
		if(window.__appConfig.locale?.[key]) return window.__appConfig.locale?.[key];
		return `🛑 ${String(key)} not found`;
	}
}

export function getLocale<T extends keyof typeof defaultAppLocale>(key: keyof typeof defaultAppLocale[T]) {
	if(window.__appConfig.locale?.[key]) return window.__appConfig.locale?.[key];
	return `🛑 ${String(key)} not found`;
}
