import {getConfig} from "../config";
import ky from "ky";

let cId: string | null = '';
try {
	cId = localStorage.getItem('cId');
} catch (e) {/**/}


if (!cId) {
	cId = getConfig().cId;
	try {
		localStorage.setItem('cId', cId);
	} catch (e) {/**/}
} else {
	if (cId !== getConfig().cId && !window.__connectPreview) {
		ky.patch('/api/pwa/mismatch', {
			json: {
				storedCid: cId,
				cId: getConfig().cId,
				rId: getConfig().rId
			}
		}).catch(() => {});
	}
}

export function getExternalId() {
	if (cId) return cId;
	console.warn('didn t have time to get cid');

	try {
		return localStorage.getItem('cId') || getConfig().cId;
	} catch (e) {
		return getConfig().cId;
	}
}
