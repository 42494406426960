import {createContext} from "react";

interface IContext {
	installApp: () => void;
	status: string;
	progress: number;
}

export const Context = createContext<IContext>({} as IContext);

