import {useEffect, useMemo, useState} from "react";
import {getConfig} from "../../../config";
import {IGPlayInfo} from "@shared/interfaces/IWebAppWorkerRecord";
import {DeepPartial} from "@shared/utils/deepPartial.ts";
import {TWebAppTemplateData} from "@shared/_data/webAppsDesignConfig/config.js";
import {TWebAppsDesignTemplateKeys} from "@shared/_data/webAppsDesignConfig/utils.ts";

function useConfigAppContent<T extends TWebAppsDesignTemplateKeys>(): IGPlayInfo<T> {
	return useMemo(() => getConfig().gPlayAppInfo, []) as IGPlayInfo<T>;
}

function usePreviewContent<T extends TWebAppsDesignTemplateKeys>(): IGPlayInfo<T> {
	const [data, setData] = useState<any>(null);
	useEffect(() => {
		window.addEventListener('message', (event) => {
			const { type, data } = event.data;
			console.log({type, data});
			setData(data);
		});

		window.parent?.postMessage({type: 'onReady'}, '*');
	}, []);

	console.log('usePreviewContent', { data });

	return ({
		installButton: {animSpeed: 0},
		screens: [],
		reviews: [],
		appSecurity: {},
		ratingCharts: {},
		tags: [],
		description: '',
		dataMap: {
			locale: {},
			additionalData: {}
		},
		templateKey: window.__appConfig?.templateKey,
		...data
	} as DeepPartial<IGPlayInfo> & { dataMap?: DeepPartial<TWebAppTemplateData<T>> }) as any;
}


export const useAppContent: <T extends TWebAppsDesignTemplateKeys>() => IGPlayInfo<T> = window.__connectPreview ? usePreviewContent : useConfigAppContent;
