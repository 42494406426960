import ky from "ky";
import {getExternalId} from "./getExternalId.tsx";
import {getConfig} from "../config";
import {getNextSequenceNumber} from "./logger.tsx";

export async function emitAppStarted() {
	const searchParams = new URLSearchParams(window.location.search);
	return ky.patch('/api/pwa/appRun', {
		json: {
			cId: getExternalId(),
			rId: getConfig().rId,
			seq: getNextSequenceNumber(),
			pushId: searchParams.get('pushId')
		}
	}).catch(() => {});
}
