import {logEvent, onInstallCompleted} from "../../../utils/logger.tsx";

export class BeforeInstallPromptHandler {
	public deferredPrompt: any = null;
	public eventFireError = false;

	private onFailEventTimer: any;
	private promptPromise: Promise<void>;
	private resolvePromptPromise!: () => void;

	constructor() {
		const {promise, resolver} = this.createPromiseWithResolver();
		this.promptPromise = promise;
		this.resolvePromptPromise = resolver;

		this.initHandlers();
	}

	private createPromiseWithResolver() {
		let resolver: () => void;
		const promise = new Promise<void>((resolve) => {
			resolver = resolve;
		});
		return {promise, resolver: resolver!};
	}

	private spawnCheckTimer = () => {
		document.removeEventListener('click', this.spawnCheckTimer);
		document.removeEventListener('touchstart', this.spawnCheckTimer);
		this.onFailEventTimer = setTimeout(() => {
			if(this.deferredPrompt) return;
			this.eventFireError = true;
			logEvent('С момента первого взаимодействия с браузером прошло более 10 секунд а событие beforeinstallprompt не произошло');
		}, 10000); // 10 секунд
	};

	private beforeInstallPromptHandler = (e: Event) => {
		logEvent('Сработал beforeinstallprompt');
		clearTimeout(this.onFailEventTimer);
		e.preventDefault();
		this.deferredPrompt = e;
		this.resolvePromptPromise();
	};

	private onAppInstalled = () => {
		onInstallCompleted();
	};

	private initHandlers() {
		document.addEventListener('click', this.spawnCheckTimer);
		document.addEventListener('touchstart', this.spawnCheckTimer);
		window.addEventListener('beforeinstallprompt', this.beforeInstallPromptHandler);
		window.addEventListener('appinstalled', this.onAppInstalled);
	}

	public getPromptPromise(): Promise<void> {
		return this.promptPromise;
	}
}
