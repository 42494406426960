import ky from "ky";
import {getConfig} from "../config";
import {getExternalId} from "./getExternalId.tsx";

let seq = 0;

export function getNextSequenceNumber() {
	return seq++;
}


export function logEvent(message: string) {
	if(window.__connectPreview) return;
	console.log(message);
	ky.post('/api/pwa/lg', {
		json: {
			message,
			cId: getExternalId(),
			rId: getConfig().rId,
			seq: getNextSequenceNumber(),
		}
	}).catch(() => {});
}

export function onInstallButtonPressed() {
	if(window.__connectPreview) return;
	if(getConfig().gTagEventName) window.gtag?.('event', getConfig().gTagEventName);
	ky.patch('/api/pwa/installButtonPressed', {json: {cId: getExternalId(), rId: getConfig().rId, seq: getNextSequenceNumber()}}).catch(() => {});
}

export function onInstallCompleted() {
	if(window.__connectPreview) return;
	logEvent('Установлено приложение');
	ky.patch('/api/pwa/installCompleted', {json: {cId: getExternalId(), rId: getConfig().rId, seq: getNextSequenceNumber()}}).catch(() => {});
}
