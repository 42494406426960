import {safeGetItemFromStorage, safeSetItemToStorage} from "../../utils/safeSetItemToStorage.tsx";
import {getConfig} from "../../config";
import ky from "ky";
import {getNextSequenceNumber} from "../../utils/logger.tsx";
import { getExternalId } from "../../utils/getExternalId.tsx";

export async function getAppLink() {
	try {
		const cachedValue = safeGetItemFromStorage(`appLink:${getConfig().offerCacheKey}`);
		if (cachedValue) return cachedValue;

		let storedSearchParams = new URLSearchParams('');
		try {
			storedSearchParams = new URLSearchParams(localStorage.getItem('storedQs') || '');
		} catch (e) {/**/}

		const mergedSp = new URLSearchParams({
			...Object.fromEntries(new URLSearchParams(window.location.search).entries()),
			...Object.fromEntries(storedSearchParams.entries()),
			external_id: getExternalId(),
		});

		const result = await ky.post('/api/pwa/appLink', {
			json: {
				query: Object.fromEntries(mergedSp.entries()),
				cId: getConfig().cId,
				rId: getConfig().rId,
				seq: getNextSequenceNumber(),
			}
		}).json<{ link: string }>();
		safeSetItemToStorage(`appLink:${getConfig().offerCacheKey}`, result.link);
		return result.link;
	} catch (e) {
		return `about:blank`;
	}
}
