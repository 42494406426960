export function safeGetItemFromStorage(key: string) {
	try {
		return localStorage.getItem(key);
	} catch (e) {
		return null;
	}
}

export function safeSetItemToStorage(key: string, value: string) {
	try {
		localStorage.setItem(key, value);
	} catch (e) {/**/
	}
}
