import {logEvent} from "../../../utils/logger.tsx";
import {trySubscribeToSelfPush} from "../../../utils/trySubscribeToSelfPush.tsx";

export function pullAppInstaller(onInstall: () => void) {
	let lock = false;
	async function checkFn() {
		if (lock) return;
		lock = true;
		if ('getInstalledRelatedApps' in window.navigator) {
			const res = await (navigator as any).getInstalledRelatedApps();
			if (res.length) {
				logEvent('Приложение успешно установлено, обнаружено через getInstalledRelatedApps()')
				trySubscribeToSelfPush('install', 'getInstalledRelatedApps').catch((err: Error) => logEvent(`Error in trySubscribeToSelfPush: ${err}`));
				return onInstall();
			}
			setTimeout(checkFn, 290);
		} else {
			logEvent('Клиент не поддерживает getInstalledRelatedApps()')
		}
		lock = false;
	}

	checkFn().catch(() => {
	});
	return;
}
