import * as React from "react";
import {useEffect, useState} from "react";
import {useAppContent} from "./templates/playMarket/logic/useAppContent.tsx";
import {PreviewProvider} from "./templates/playMarket/logic/previewProvider.tsx";
import {InstallerProvider} from "./templates/playMarket/logic/installerProvider.tsx";

const templatesObj = import.meta.glob<{ default: React.ComponentType<any> }>('/src/templates/*/index.tsx');

const templates = Object.entries(templatesObj).reduce((acc, [key, value]) => {
    // /src/templates/{templateKey}/index.tsx
    const templateKey = key.split('/')[3]!;
    acc[templateKey] = value;

    return acc;
}, {} as Record<string, () => Promise<{ default: React.ComponentType }>>);

function TemplateRenderer() {
    const config = useAppContent();
    const [Component, setComponent] = useState<{ default: React.ComponentType } | null | 'error'>(null);
    const [templateError, setErr] = useState<Error>();

    useEffect(() => {
        if (!(config.templateKey in templates)) {
            setComponent(null);
            setErr(new Error(`Template not found (${config.templateKey})`));
        } else {
            console.log('load template', config.templateKey);
            templates[config.templateKey || 'playMarket']!()
                .then(component => {
                    console.log('component', component);
                    if(!component) return setErr(new Error('Template loaded is Null component'));
                    setErr(undefined);
                    setComponent(component);
                }).catch(err => setErr(err))
        }
    }, [config.templateKey]);
    if (templateError) return (
        <div>
            Error: {templateError.message}
            <br/>
            <pre>{templateError.stack}</pre>
        </div>
    );

    if (Component === null) return '...';

    if (Component === 'error') return (
        <div>
            Template not found {config.templateKey}
        </div>
    );

    const Provider = window.__connectPreview ? PreviewProvider : InstallerProvider;

    return (
        <Provider>
            <Component.default />
        </Provider>
    );
}

export default TemplateRenderer;
