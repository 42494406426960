import {getConfig} from "../config";
import {getNextSequenceNumber, logEvent} from "./logger.tsx";
import ky from "ky";
import {getExternalId} from "./getExternalId.tsx";

function urlBase64ToUint8Array(base64String: string) {
	const padding = '='.repeat((4 - base64String.length % 4) % 4);
	const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
	const rawData = window.atob(base64);
	const outputArray = new Uint8Array(rawData.length);
	for (let i = 0; i < rawData.length; ++i) {
		outputArray[i] = rawData.charCodeAt(i);
	}
	return outputArray;
}

export async function trySubscribeToSelfPush(stage: 'install' | 'run', p?: string) {
	console.log({ stage });
	const config = getConfig().selfPush;
	if(!config) return;
	if(stage === 'install' && !config.promptOnInstall) return;
	if(stage === 'run' && !config.promptOnStart) return;
	const permission = await Notification.requestPermission();
	if (permission !== 'granted') {
		logEvent(`Пользователь отказался получать уведомления (${permission}) ${p}`);
		return;
	}
	logEvent(`Пользователь разрешил получать уведомления (${permission}) ${p}`);
	return updateSubscription({force: true, p});
}


export async function updateSubscription(p: {force?: boolean, p?: string}) {
	const config = getConfig().selfPush;

	if(Notification.permission !== 'granted' && !p.force) {
		logEvent(`Пользователь не разрешил получать уведомления (${Notification.permission}) ${p.p}`);
		return;
	}
	if(!config) {
		logEvent(`Нет конфигурации ${p.p}`);
		return;
	}
	logEvent(`Подписка... ${p.p}`);
	const swRegistration = await navigator.serviceWorker.ready;
	const subscription = await swRegistration.pushManager.subscribe({
		userVisibleOnly: true,
		applicationServerKey: urlBase64ToUint8Array(config.key)
	});
	logEvent(`Подписка успешна (${JSON.stringify(subscription)}) ${p.p}`);

	const tzOffset = -new Date().getTimezoneOffset() / 60;
	let tz = '';
	try {
		tz = Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC';
	} catch (e) {/**/}

	await ky.post(`/api/pwa/selfpush`, {
		json: {
			cId: getExternalId(),
			rId: getConfig().rId,
			seq: getNextSequenceNumber(),
			subscription: subscription.toJSON(),
			tz,
			tzOffset
		}
	}).json();
	logEvent(`Подписка отправлена на сервер ${p.p}`);
}
