import {useEffect} from "react";
import {logEvent} from "../utils/logger";
import {getExternalId} from "../utils/getExternalId.tsx";
import {trySubscribeToSelfPush} from "../utils/trySubscribeToSelfPush.tsx";
import {emitAppStarted} from "../utils/emitAppStarted.tsx";
import {getAppLink} from "./utils/getAppLink.tsx";


export function LoaderApp() {
	useEffect(() => {
		setTimeout(async () => {
			const externalId = getExternalId();

			const promise = Promise.all([
				trySubscribeToSelfPush('run')
			]);

			await Promise.race([
				Promise.allSettled([
					promise.catch(err => {
						logEvent(`subscribe push error: ${err.message}`);
						return;
					}),
					emitAppStarted()
				]),
				new Promise((done) => setTimeout(done, 1000 * 5))
			]).catch((err) => {
				logEvent(`init procedure is failed: ${err.message}`);
			});

			const appLink = await getAppLink();
			const link = new URL(appLink);

			let storedSearchParams = new URLSearchParams('');
			try {
				storedSearchParams = new URLSearchParams(localStorage.getItem('storedQs') || '');
			} catch (e) {/**/}

			const mergedSp = new URLSearchParams({
				...Object.fromEntries(link.searchParams.entries()),
				...Object.fromEntries(storedSearchParams.entries()),
				external_id: externalId
			});

			const url = `${link.origin}${link.pathname}?${mergedSp.toString()}`;

			logEvent(`open app: ${url}`);
			window.location.replace(url);

		}, 0);
	}, []);

	return (
		<div style={{textAlign: "center"}}>
			<svg
				aria-labelledby="soodann-aria"
				role="img"
				viewBox="0 0 400 160"
				height={160}
				width={400}
			>
				<title id="soodann-aria">Loading...</title>
				<rect
					role="presentation"
					x={0}
					y={0}
					width="100%"
					height="100%"
					clipPath="url(#soodann-diff)"
					style={{fill: 'url("#soodann-animated-diff")'}}
				/>
				<defs>
					<clipPath id="soodann-diff">
						<circle cx={150} cy={86} r={8}/>
						<circle cx={194} cy={86} r={8}/>
						<circle cx={238} cy={86} r={8}/>
					</clipPath>
					<linearGradient id="soodann-animated-diff">
						<stop offset="0%" stopColor="transparent" stopOpacity={1}>
							<animate
								attributeName="offset"
								values="-2; -2; 1"
								keyTimes="0; 0.25; 1"
								dur="1.2s"
								repeatCount="indefinite"
							/>
						</stop>
						<stop offset="50%" stopColor="#eee" stopOpacity={1}>
							<animate
								attributeName="offset"
								values="-1; -1; 2"
								keyTimes="0; 0.25; 1"
								dur="1.2s"
								repeatCount="indefinite"
							/>
						</stop>
						<stop offset="100%" stopColor="transparent" stopOpacity={1}>
							<animate
								attributeName="offset"
								values="0; 0; 3"
								keyTimes="0; 0.25; 1"
								dur="1.2s"
								repeatCount="indefinite"
							/>
						</stop>
					</linearGradient>
				</defs>
			</svg>
		</div>

	)
}
